import { createReducer, on } from '@ngrx/store';

import * as ProfileChangeActions from './profilechange.actions';
import { ProfileFormChangeInitialState } from './profilechange.state';

export const profileChangeReducer = createReducer(
  ProfileFormChangeInitialState,
  on(ProfileChangeActions.update, state => ({ ...state, loading: true })),
  on(ProfileChangeActions.updateUnsavedSuccess, (state, { unsavedProfile }) => ({
    ...state,
    loading: false,
    unsavedProfile: unsavedProfile,
  })),

  on(ProfileChangeActions.clear, state=> ({
    ...state,
    unsavedProfile: false,
  })),

  on(ProfileChangeActions.clearFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
