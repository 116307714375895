import { Directive, HostListener, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ApplicationActions, AppState, selectSession } from "../core/store";

@Directive({selector: '[mouseDir]'})
export class MouseDirective implements OnInit{

    constructor(private store: Store<AppState>) { }
    ngOnInit(): void {
    }
    @HostListener('click') onClick(){
        this.store.select(selectSession).subscribe(
            data => {
                if(data.UserId){
                    this.store.dispatch(ApplicationActions.resetPreLogout())
                    this.store.dispatch(ApplicationActions.resetTimer())
                    this.store.dispatch(ApplicationActions.refreshToken())
                }
            })        
        }

    @HostListener('dblclick') ondblClick(){
        this.store.select(selectSession).subscribe(
            data => {
                if(data.UserId){
                    this.store.dispatch(ApplicationActions.resetPreLogout())
                    this.store.dispatch(ApplicationActions.resetTimer())
                    this.store.dispatch(ApplicationActions.refreshToken())
                }
            })        
    }

    @HostListener('window:keydown', ['$event'])onKeyDown() {
        this.store.select(selectSession).subscribe(
            data => {
                if(data.UserId){
                    this.store.dispatch(ApplicationActions.resetPreLogout())
                    this.store.dispatch(ApplicationActions.resetTimer())
                    this.store.dispatch(ApplicationActions.refreshToken())
                }
            })        
    }
  
}