import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { faEarthAmericas } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { Observable, startWith, Subscription } from 'rxjs';

import { NavBarTab, TabSubView } from './model';
import { DefaultTab, HeaderConfig } from '../models/headerConfig';
import { sub } from 'date-fns';

/**
 * A container for holding content in the start of the global bar
 */
@Component({
  selector: 'lib-tabs-bar-end, [lib-tabs-bar-end], [libTabsBarEnd]',
  template: `
    <ng-template #templateRef>
      <ng-content></ng-content>
    </ng-template>
  `,
  exportAs: 'libTabsBarEnd',
})
export class TabsBarEndComponent {
  /**
   * THe template ref of the content
   */
  @ViewChild('templateRef', { static: true })
  public templateRef!: TemplateRef<unknown>;
}

/**
 * A container for holding content in the body of the mobile side nav
 */
@Component({
  selector:
    'lib-tabs-bar-body-mobile, [lib-tabs-bar-body-mobile], [libTabsBarBodyMobile]',
  template: `
    <ng-template #templateRefMobile>
      <ng-content></ng-content>
    </ng-template>
  `,
  exportAs: 'libTabsBarBodyMobile',
})
export class TabsBarBodyMobileComponent {
  /**
   * The template ref of the content
   */
  @ViewChild('templateRefMobile', { static: true })
  public templateRefMobile!: TemplateRef<unknown>;
}

/**
 * A container for holding content in the start of the global bar
 */
@Component({
  selector:
    'lib-tabs-bar-end-mobile, [lib-tabs-bar-end-mobile], [libTabsBarEndMobile]',
  template: `
    <ng-template #templateRefMobile>
      <ng-content></ng-content>
    </ng-template>
  `,
  exportAs: 'libTabsBarEndMobile',
})
export class TabsBarEndMobileComponent {
  /**
   * THe template ref of the content
   */
  @ViewChild('templateRefMobile', { static: true })
  public templateRefMobile!: TemplateRef<unknown>;
}

/**
 * Component for displaying global navigation tabs and handling tab-related events.
 *
 * This component provides a top navigation bar with tabs and various interaction features.
 * It can display custom logo images and emit events when tabs change.
 *
 * @selector 'tabs-bar'
 *
 * @usageNotes
 * To use this component, include it in your Angular application and provide input data for the tabs and other options.
 *
 * @example
 * <lib-tabs-bar
 *   [showLoginBtn]="true"
 *   [customLogoImg]="'assets/pearsonvue-logo.svg'"
 *   [tabs]="tabsData"
 *   (onTabChange)="handleTabChange($event)"
 *   (onViewChange)="handleSubviewClick($event)"
 *   (onButtonChange)="handleLoginClick($event)"
 *   (onGlobeChange)="handleGlobeClick()">
 * </-tabs-bar>
 */
@Component({
  selector: 'lib-tabs-bar',
  templateUrl: './tabs-bar.component.html',
  styleUrls: ['./tabs-bar.component.scss'],
})
export class TabsBarComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatTabGroup) matTabGroup!: MatTabGroup;

  @ViewChild(MatSidenav, { static: false }) matSidenav!: MatSidenav;
  /**
   * The End component template
   */
  @ContentChild(TabsBarEndComponent, { static: true })
  public tabsEndContent!: TabsBarEndComponent;

  /**
   * The Body component template Mobile
   */
  @ContentChild(TabsBarBodyMobileComponent, { static: true })
  public tabsBodyContentMobile!: TabsBarBodyMobileComponent;

  /**
   * The End component template Mobile
   */
  @ContentChild(TabsBarEndMobileComponent, { static: true })
  public tabsEndContentMobile!: TabsBarEndMobileComponent;

  /**
   * Whether to display the login button in the component.
   */
  @Input() showLoginBtn = true;

  /**
   * Text to display on the login button in the component.
   */
  @Input() buttonLabel = 'Log in';

  /**
   * Whether to display the globe icon button in the component.
   */
  @Input() showMenu = true;

  /**
   * Event emitted when a tab change occurs, providing the label of the selected tab.
   */
  @Output() tabChangeEvent: EventEmitter<NavBarTab> =
    new EventEmitter<NavBarTab>();

  /**
   * Event emitted when a tab subview/link change occurs, providing the label of the selected tab.
   */
  @Output() viewChangeEvent: EventEmitter<TabSubView> = new EventEmitter();

  /**
   * Event emitted when the login button is clicked, indicating whether the drawer is opened.
   */
  @Output() buttonChangeEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /**
   * Event emitted when the globe icon button is clicked.
   */
  @Output() globeChangeEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /**
   * Flag to track whether a panel is opened or closed.
   */
  panelOpened = false;

  /**
   * The currently active tab, represented by a `NavBarTab` object.
   */
  @Input() activeTab!: NavBarTab;

  /**
   * An array of subviews corresponding to the active tab.
   */
  activeSubView: string[] = [];

  /**
   * Flag to track whether the tab initialization is complete.
   */
  tabInitComplete = false;

  /**
   * Icons used in the component, such as FontAwesome icons.
   */
  icons = {
    faEarthAmericas,
    faXmark,
  };

  /**
   * Reference to the MatTabGroup for programmatic interaction.
   */
  @ViewChild(MatTabGroup) tabRef!: MatTabGroup;

  /**
   * URL for a custom logo image to be displayed in the component.
   * Default value is 'assets/pearsonvue-logo.svg'.
   */
  @Input() public customLogoImg?: string;

  @Input() public logoAltText?: string = 'pearson logo';

  /**
   * Array of tab data, represented by `NavBarTab` objects, to be displayed in the component.
   */
  @Input() tabs: NavBarTab[] = [];

  /**
   * Observable of Array of tab data, represented by `NavBarTab` objects, to be displayed in the component.
   */
  @Input() $tabs!: Observable<NavBarTab[]>;

  /**
   * Observable of the header configuration object.
   */
  @Input() $headerConfig!: Observable<HeaderConfig>;

  @Output() logoClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * An Observable that detects if the screen is in a mobile view (small screen).
   */
  isMobileView: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset,
  );

  subscriptions: Subscription[] = [];

  constructor(private breakpointObserver: BreakpointObserver) {}

  /**
   * Handles the tab change event and emits the selected tab's label.
   *
   * @param evt - The `MatTabChangeEvent` object representing the tab change event.
   */
  handleTabChange(evt: MatTabChangeEvent) {
    if (this.$tabs) {
      this.activeTab?.subViews.forEach(t => (t.active = false));
      const tab = this.tabs?.find(t => t.label === evt?.tab?.textLabel);
      if (tab) {
        this.activeTab = tab;

        this.tabChangeEvent.emit(tab);
        if (this.activeTab?.subViews?.length > 0) {
          this.activeTab.subViews[0].active = true;
        }
      }
    } else {
      this.activeTab.subViews.forEach(t => (t.active = false));
      const tab = this.tabs.find(t => t.label === evt.tab.textLabel);
      if (tab) {
        this.activeTab = tab;
      }
      this.tabChangeEvent.emit(tab);
      if (this.activeTab.subViews.length > 0) {
        this.activeTab.subViews[0].active = true;
      }
    }
  }

  ngOnInit(): void {
    if (this.$headerConfig) {
      this.setupViaHeaderConfigObservable(this.$headerConfig);
    } else if (this.$tabs) {
      this.setupViaTabsObservable(this.$tabs);
    } else {
      this.setupTabs(this.tabs);
    }
  }

  setupViaHeaderConfigObservable(headerConfig: Observable<HeaderConfig>): void {
    this.subscriptions.push(
      headerConfig.subscribe(hConfig => {
        if (hConfig.tabs) {
          this.setupTabs(hConfig.tabs);
        }
      }),
    );
  }

  setupViaTabsObservable($tabs: Observable<NavBarTab[]>): void {
    this.subscriptions.push(
      $tabs.pipe(startWith([])).subscribe(observedTabs => {
        if (observedTabs) {
          this.setupTabs(observedTabs);
        }
      }),
    );
  }
  setupTabs(tabs: NavBarTab[]): void {
    // get the active tab and subview
    const activeTab = this.tabs
      ? this.tabs.find(tab => tab.id === this.activeTab.id)
      : undefined;
    const activeSubView = this.activeTab
      ? this.activeTab.subViews.filter(view => view.active)
      : undefined;
    // get the index of the active tab and subview from the new tab array
    const activeTabIndex =
      tabs.findIndex(tab => tab.id === activeTab?.id) || -1;

    this.tabs = tabs;
    // set the active tab to the previously active tab in the new tab array or the first tab if the active tab is not found
    this.activeTab =
      this.tabInitComplete && activeTabIndex > 0
        ? this.tabs[activeTabIndex]
        : this.tabs[0];
    this.activeSubView = this.activeTab.subViews.map(v => v.label);

    // set the active subview to the previously active subview in the new tab array or the first subview if the active subview is not found
    if (this.activeTab.subViews.length > 0) {
      const activeSubViewIndex = this.activeSubView
        ? this.tabs[activeTabIndex]?.subViews.findIndex(
            view => view.label === (activeSubView?.[0]?.label ?? ''),
          )
        : -1;
      if (this.tabInitComplete && activeSubViewIndex > 0) {
        this.activeTab.subViews[activeSubViewIndex].active = true;
      } else {
        this.activeTab.subViews[0].active = true;
      }
    }
    this.tabInitComplete = true;
  }

  /**
   * Get an array of non-active tabs.
   *
   * @returns An array of `NavBarTab` objects that are not currently active.
   */
  get nonActiveTabs() {
    return this.tabs.filter(t => t.label !== this.activeTab?.label);
  }

  /**
   * Handle a click event on a parent tab view.
   *
   * @param view - The `NavBarTab` object representing the clicked parent tab.
   */
  handleParentClick(view: NavBarTab) {
    this.activeTab = view;
    this.tabRef.selectedIndex = this.tabs.map(t => t.label).indexOf(view.label);
  }

  /**
   * Handle a click event on the login button and emit the event.
   *
   * @param drawer - The `MatSidenav` object representing the drawer.
   */
  handleLoginClick(drawer: MatSidenav) {
    this.panelOpened = drawer.opened;
    this.buttonChangeEvent.emit(true);
  }

  /**
   * Toggle the state of the drawer and update the `panelOpened` flag.
   *
   * @param drawer - The `MatSidenav` object representing the drawer.
   */
  toggleDrawer(drawer: MatSidenav) {
    drawer.toggle();
    this.panelOpened = drawer.opened;
  }

  /**
   * Handle a click event on a subview and emit the event.
   *
   * @param subView - The subview label that was clicked.
   */
  handleSubviewClick(subView: TabSubView, drawer: MatSidenav | null) {
    this.activeTab?.subViews.forEach(item => {
      if (item.label === subView?.label) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    if (drawer) {
      this.handleClose();
    }
    this.viewChangeEvent.emit(subView);
  }

  /**
   * Handle a click event on the globe icon and emit the event.
   */
  handleGlobeClick() {
    this.globeChangeEvent.emit();
  }

  handleLogoClick(evt: boolean) {
    this.logoClicked.emit(evt);
  }

  selectTab(selectedTab: string) {
    this.matTabGroup.selectedIndex = this.tabs
      .map(tab => tab.label)
      .indexOf(selectedTab);
  }

  handleClose() {
    this.matSidenav.close();
    this.panelOpened = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activTab']) {
      this.selectTab(this.activeTab?.label);
    }
    if (changes['activeSubView']) {
      this.selectTab(this.activeTab?.label);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
