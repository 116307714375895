import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { NavBarTab, TabSubView } from '@pearsonvue/topaz-angular-ui';
import { Observable, map } from 'rxjs';

import {
  DefaultTab,
  HeaderConfig,
  OrgAdminTab,
  ProctorTab,
  TCGroupAdminTab,
  TeacherTab,
  TestCandidateTab,
  GlobalCandidateTab,
} from '@src/libs/ui/src/lib/models/headerConfig';

import { CpHttpClientService } from '@src/libs/data-access/src/lib/services/cp-http-client.service';
import { APP_CONFIG, Config } from '../config/config';
import { DefaultProfile, PatchObj, Profile } from '../core/models';
import { AppState, selectRoles, selectSession } from '../core/store';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    protected store: Store<AppState>,
    private translationService: TranslocoService,
    private _http: CpHttpClientService,
  ) {}

  fetch(): Observable<Profile> {
    return this._http
      .get<Profile>(`${this.config.microServiceURI}/usermanagement/myprofile`)
      .pipe(
        map(({ success, data }) => {
          if (success) {
            return data;
          } else {
            return DefaultProfile;
          }
        }),
      );
  }

  private loggedOut(): Profile {
    return DefaultProfile;
  }

  update(patchs: PatchObj[]): Observable<Profile> {
    return this.fetch();
  }

  getAuthStatus(): Observable<boolean> {
    return this.store
      .select(selectSession)
      .pipe(map(session => !!session.PortalUserSessionID));
  }

  getHeaderRoles(): Array<number> {
    return [1, 312, 3, 23, 390];
  }

  getHeaderConfig(): Observable<HeaderConfig> {
    return this.store.select(selectRoles).pipe(
      map(roles => {
        var headerConfig: HeaderConfig = { tabs: [] };

        if (roles?.length) {
          roles.forEach(role => {
            this.selectRoleConfig(headerConfig, role.roleID);
          });
        } else {
          headerConfig.tabs.push(DefaultTab);
        }

        return headerConfig;
      }),
    );
  }

  selectRoleConfig(headerConfig: HeaderConfig, roleID: number): void {
    switch (roleID) {
      //Test Candidate  - everyone except JPN users get this role by default
      case 1:
        headerConfig.tabs.push(TestCandidateTab);
        break;
      //Global Candidate - JPN users get this role by default
      case 313:
        headerConfig.tabs.push(GlobalCandidateTab);
        break;
      //Instructor/Teacher
      case 312:
        headerConfig.tabs.push(TeacherTab);
        break;
      //Proctor
      case 3:
        headerConfig.tabs.push(ProctorTab);
        break;
      //OrgAdmin
      case 23:
        headerConfig.tabs.push(OrgAdminTab);
        break;
      //Test Center Group Admin
      case 390:
        headerConfig.tabs.push(TCGroupAdminTab);
        break;
    }
  }
}
