<ng-container *transloco="let t">
  <mat-form-field appearance="outline">
    <mat-label data-id="res-s1-password-label">{{ label }}</mat-label>
    <input
      matInput
      required
      placeholder="********"
      [formControl]="control"
      [type]="showPassword ? 'text' : 'password'"
      (focus)="onPasswordFocusInEvent($event)"
      (focusout)="onPasswordFocusOutEvent($event)"
      data-id="res-s1-password-input"
    />
    <fa-icon
      matIconPrefix
      [icon]="faLock"
      [ngClass]="[hasError() ? 'red' : '']"
    ></fa-icon>
    <fa-icon
      matSuffix
      class="eye"
      [icon]="showPassword ? faEye : faEyeSlash"
      (click)="togglePasswordVisibility()"
      (focus)="onPasswordFocusInEvent($event)"
    ></fa-icon>
    <mat-error *ngIf="passwordError()" data-id="res-s1-password-error">
      {{ getValidationText() }}
    </mat-error>
  </mat-form-field>
  <div [@slideInOut]="animationState" class="password-error-criterion" *ngIf="passwordHint">
    <h6 topazTitle="6" data-id="res-s1-passwordValidator-title">
      {{ t('passwordValidators.title') }}
    </h6>
    <ul class="fa-ul" data-id="res-s1-password-error">
      <li
        *ngFor="let error of passwordErrors; let i = index"
        data-id="res-s1-password-error-{{ i + 1 }}"
      >
        <span>
          <fa-icon
            *ngIf="control?.value"
            size="xs"
            [ngClass]="[getPasswordError(error) ? 'red' : 'green']"
            [icon]="getPasswordError(error) ? faX : faCheck"
          ></fa-icon>
          <fa-icon
            *ngIf="!control?.value"
            class="black"
            size="xs"
            transform="shrink-9 right-4"
            [icon]="faCircle"
          ></fa-icon>
        </span>
        {{ t('passwordValidators.' + error) }}
      </li>
    </ul>
  </div>
</ng-container>
