import { createReducer, on } from '@ngrx/store';

import * as ProfileActions from './profile.actions';
import { profileInitialState } from './profile.state';
import { DefaultProfile } from '../../models';

export const profileReducer = createReducer(
  profileInitialState,
  on(ProfileActions.fetch, state => ({ ...state, loading: true })),
  on(ProfileActions.fetchSuccess, (state, { profile }) => ({
    ...state,
    profile,
    loading: false,
    error: '',
    unsaved_profile: false,
  })),
  on(ProfileActions.fetchFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(ProfileActions.fetchUnsavedForm, (state, { unsaved_profile }) => ({
    ...state,
    loading: false,
    unsaved_profile: unsaved_profile,
  })),

  on(ProfileActions.update, state => ({ ...state, loading: true })),
  on(ProfileActions.updateSuccess, (state, { profile }) => ({
    ...state,
    profile,
    loading: false,
    error: '',
  })),
  on(ProfileActions.updateFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(ProfileActions.updateUnsavedForm, (state, { unsaved_profile }) => ({
    ...state,
    loading: false,
    unsaved_profile: unsaved_profile
  })),

  on(ProfileActions.clear, state => ({
    ...state,
    profile: DefaultProfile,
    error: '',
    unsaved_profile: false,
  })),

  on(ProfileActions.clearFailure, (state, { error }) => ({
    ...state,
    profile: DefaultProfile,
    error: error,
  })),
  
  on(ProfileActions.clearUnsaved, state=> ({
    ...state,
    profile: DefaultProfile,
    error: '',
    unsaved_profile: false,
  })),
);
