

export interface ProfileFormChangeState {
  loading: boolean;
  unsavedProfile: boolean
}

export const ProfileFormChangeInitialState: ProfileFormChangeState = {
  loading: false,
  unsavedProfile: false
};
