<!--login form-->

<div class="row step1padd" *transloco="let t">
  <div class="col-lg">
    <h1
      topazTitle="1"
      class="text-center d-none d-lg-block"
      data-id="res-s1-h1-join"
    >
      {{ t('common.join') }}
    </h1>
    <form [formGroup]="loginFormGroup">
      <lib-custom-banner *ngIf="loginFormGroup.invalid && formSubmit">
        <p class="m-0" data-id="reg-error-banner">
          <b>{{ t('common.uiErrorBanner.errorsHaveBeenFound') }}</b>
        </p>
      </lib-custom-banner>
      <div>
        <mat-form-field appearance="outline">
          <mat-label data-id="reg-country-label">{{
            t('common.country')
          }}</mat-label>
          <input
            required
            matInput
            type="text"
            formControlName="country"
            [matAutocomplete]="countryAuto"
            data-id="reg-country-input"
          />
          <fa-icon [icon]="faCaretDown" size="sm" matIconSuffix></fa-icon>
          <mat-autocomplete
            #countryAuto="matAutocomplete"
            data-id="reg-country-autocomp"
          >
            <mat-option
              *ngFor="let item of filteredCountries | async"
              [value]="item.name"
            >
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
          <fa-icon
            matIconPrefix
            size="sm"
            [icon]="faFlag"
            [ngClass]="[hasError('country') ? 'red' : '']"
          ></fa-icon>
          <mat-error *ngIf="hasError('country')" data-id="res-s1-coutry-error">
            {{ getValidationText('country') }}
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <lib-email  formControlName="email" label="common.email"></lib-email>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label data-id="res-s1-password-label">{{
            t('common.password')
          }}</mat-label>
          <input
            matInput
            required
            placeholder="********"
            [formControlName]="'password'"
            [type]="showPassword ? 'text' : 'password'"
            (focus)="onPasswordFocusInEvent($event)"
            (focusout)="onPasswordFocusOutEvent($event)"
            data-id="res-s1-password-input"
          />
          <fa-icon
            matIconPrefix
            [icon]="faLock"
            [ngClass]="[hasError('password') ? 'red' : '']"
          ></fa-icon>
          <fa-icon
            matSuffix
            class="eye"
            [icon]="showPassword ? faEye : faEyeSlash"
            (click)="togglePasswordVisibility()"
            (focus)="onPasswordFocusInEvent($event)"
          ></fa-icon>
          <mat-error *ngIf="passwordError()" data-id="res-s1-password-error">
            {{ getValidationText('password') }}
          </mat-error>
        </mat-form-field>

        <div [@slideInOut]="animationState" class="password-error-criterion">
          <h6 topazTitle="6" data-id="res-s1-passwordValidator-title">
            {{ t('passwordValidators.title') }}
          </h6>
          <ul class="fa-ul" data-id="res-s1-password-error">
            <li
              *ngFor="let error of passwordErrors; let i = index"
              data-id="res-s1-password-error-{{ i + 1 }}"
            >
              <span>
                <fa-icon
                  *ngIf="loginFormGroup.get('password')?.value"
                  size="xs"
                  [ngClass]="[getPasswordError(error) ? 'red' : 'green']"
                  [icon]="getPasswordError(error) ? faX : faCheck"
                ></fa-icon>
                <fa-icon
                  *ngIf="!loginFormGroup.get('password')?.value"
                  class="black"
                  size="xs"
                  transform="shrink-9 right-4"
                  [icon]="faCircle"
                ></fa-icon>
              </span>
              {{ t('passwordValidators.' + error) }}
            </li>
          </ul>
        </div>
      </div>

      <!--tos agreement-->
      <div>
        <div
          class="basic-checkboxes show-pwd-container mb-1"
          data-id="res-s1-tos-agreement"
        >
          <mat-checkbox
            #tos
            [formControlName]="'tos'"
            [ngClass]="{ 'is-invalid': isToSChecked }"
            (change)="onChangeToSCheckbox($event)"
            data-id="res-s1-tos-agreement-checkbox"
          >
            {{ t('signup.tosAgree.1') }}
            <a
              class="topaz-link-bold"
              href="javascript:void(0)"
              (click)="openUserAgreementModal($event)"
              data-id="res-s1-tos-text"
            >
              {{ t('signup.tosAgree.2') }}</a
            >*
            <mat-error
              class="is-invalid"
              *ngIf="isToSChecked"
              data-id="res-s1-tos-error"
            >
              {{ t('signup.error.required_ToS') }}
            </mat-error>
          </mat-checkbox>
        </div>
      </div>

      <!--submit button-->
      <div class="buttons full-width">
        <button
          #submitButton
          mat-flat-button
          topazButtonLoader
          type="submit"
          color="primary"
          [loading]="showSpinner"
          (click)="submit()"
          data-id="res-s1-submit-bttn"
        >
          {{ t('common.next') }}
        </button>
      </div>

      <p class="text-center account-exists" data-id="res-s1-haveaccount">
        {{ t('signup.haveAccount') }}
        <a
          class="topaz-link-bold"
          [routerLink]="['/']"
          queryParamsHandling="preserve"
          data-id="res-s1-login-link"
        >
          {{ t('common.login') }}
        </a>
      </p>

      <hr />

      <!--clever redirect-->
      <lib-clever [cleverUrl]="cleverUrl"></lib-clever>
    </form>
  </div>

  <div class="col-lg d-flex align-items-center justify-content-center">
    <div class="ml-2">
      <img
        src="assets/images/portal_join_img.png"
        alt="{{ t('login.img_alttext') }}"
        class="d-none d-lg-block"
      />
    </div>
  </div>
</div>
