import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { catchError, map, of } from 'rxjs';


import * as ProfileChangeActions from './profilechange.actions';
import { AppState } from '../../core.state';
import { Store } from '@ngrx/store';

@Injectable()
export class ProfileChangeEffects {
  profileChangeClear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileChangeActions.clear),
      map(() => ProfileChangeActions.clearSuccess()),
      catchError(error =>
        of(ProfileChangeActions.clearFailure({ error: error.message })),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    protected store: Store<AppState>,
  ) {}
}
