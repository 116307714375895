import { createAction, props } from '@ngrx/store';

import { PatchObj, Profile } from '../../models';

const enum ProfileActionKeys {
  FETCH = '[Profile] Fetch',
  FETCH_SUCCESS = '[Profile] Fetch Success',
  FETCH_FAILURE = '[Profile] Fetch Failure',
  FETCH_UNSAVEDFORM = '[Profile] Fetch Unsaved Form',
  UPDATE = '[Profile] Update',
  UPDATE_SUCCESS = '[Profile] Update Success',
  UPDATE_FAILURE = '[Profile] Update Failure',
  UPDATE_UNSAVEDFORM  = '[Profile] Update Unsaved Form',
  CLEAR = '[Profile] Clear',
  CLEAR_SUCCESS = '[Profile] Clear Success',
  CLEAR_FAILURE = '[Profile] Clear Failure',
  CLEAR_UNSAVEDFORM  = '[Profile] Clear Unsaved Form',
}

export const fetch = createAction(ProfileActionKeys.FETCH);
export const fetchSuccess = createAction(
  ProfileActionKeys.FETCH_SUCCESS,
  props<{ profile: Profile }>(),
);
export const fetchFailure = createAction(
  ProfileActionKeys.FETCH_FAILURE,
  props<{ error: string }>(),
);
export const fetchUnsavedForm = createAction(
  ProfileActionKeys.FETCH_UNSAVEDFORM,
  props<{ unsaved_profile: boolean }>(),
);

export const update = createAction(
  ProfileActionKeys.UPDATE,
  props<{ patches: PatchObj[] }>(),
);
export const updateSuccess = createAction(
  ProfileActionKeys.UPDATE_SUCCESS,
  props<{ profile: Profile }>(),
);
export const updateFailure = createAction(
  ProfileActionKeys.UPDATE_FAILURE,
  props<{ error: string }>(),
);
export const updateUnsavedForm = createAction(
  ProfileActionKeys.UPDATE_UNSAVEDFORM,
  props<{ unsaved_profile: boolean }>(),
);

export const clear = createAction(ProfileActionKeys.CLEAR);
export const clearSuccess = createAction(ProfileActionKeys.CLEAR_SUCCESS);
export const clearUnsaved = createAction(ProfileActionKeys.CLEAR_UNSAVEDFORM);
export const clearFailure = createAction(
  ProfileActionKeys.CLEAR_FAILURE,
  props<{ error: string }>(),
);
