import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, map, take ,of} from 'rxjs';

import { Country } from '../../models/countries';
import { DEFAULTCOUNTRY } from '../../../assets/countries';
import { CpHttpClientService } from '@src/libs/data-access/src/lib/services/cp-http-client.service';
import { APP_CONFIG, Config } from '@src/app/config/config';
import { LoaderService } from '@pearsonvue/topaz-angular-ui';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  countries = new BehaviorSubject<Country[]>(DEFAULTCOUNTRY);
  countries$ = this.countries.asObservable();

  sak_country_uri = `${this.config.microServiceURI}/sak/Countries?isActive=true`;

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private _http: CpHttpClientService,
    private loaderService: LoaderService,
    private translocoService: TranslocoService) {}

  get(): Observable<Country[]> {
    return this.countries.asObservable().pipe(
      map(countries =>
        (countries as Country[])
          .filter(country => country.isActive === true)
          .sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }),
      ),
    );
  }

  getCountry(): Observable<Country[]> {
    return this.countries.asObservable().pipe(
      map(countriessak =>
        (countriessak as Country[])
          .filter(country => country.isActive === true)
          .sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }),
      ),
    );
  }

  getCountryList(){
    if (this.countries.getValue() && this.countries.getValue().length < 2) { //it has one country by default, 
  
    return this._http
    .get<Country[]>(this.sak_country_uri).pipe(
      take(1),
      map((data) => {
        this.countries.next(data.data)
        return data.data
      }))
    } else {
      return of(this.countries.getValue());
    }
  }

  filterCountries(filter: string): Observable<Country[]> {
    return this.countries.asObservable().pipe(
      map(countries =>
        (countries as Country[])
          .filter(
            country =>
              country.isActive === true &&
              country.name.toLowerCase().includes(filter.toLowerCase()),
          )
          .sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }),
      ),
    );
  }


  getCountryById(countryId: number): Country | undefined {
    return this.countries.value.find(
      country => country.isActive === true && country.countryId == countryId,
    );
  }

  getCountryByDesc(countryDesc: string): Country | undefined {
    return this.countries.value.find(
      country => country.isActive === true && country.name == countryDesc,
    );
  }

  getCountryByCode(countryCode: string): Country | undefined {
    return this.countries.value.find(
      country => country.isActive === true && country.countryCode == countryCode,
    );
  }
}
