import { createSelector } from '@ngrx/store';

import { AppState } from '../../core.state';
import { ProfileFormChangeState } from './profilechange.state';

export const selectProfileFormChange = (state: AppState) => state.profileChangeState;

export const selectProfileChangeLoading = createSelector(
  selectProfileFormChange,
  (state: ProfileFormChangeState) => state.loading,
);

export const selectProfileUnsavedChanges = createSelector(
  selectProfileFormChange,
  (state: ProfileFormChangeState) => state.unsavedProfile,
);
