import { createAction, props } from '@ngrx/store';


const enum ProfileFormChangeActionKeys {
  UPDATE = '[Profile Changes] ProfileUpdate',
  UPDATE_UNSAVEDSUCCESS  = '[Profile Changes] Update Unsaved Form',
  
  CLEAR = '[Profile Changes] Clear Profile changes',
  CLEAR_UNSAVEDSUCCESS = '[Profile Changes] Clear Profile changes Success',
  CLEAR_UNSAVEDFAILURE = '[Profile Changes] Clear Profile changes Failure'
}


export const update = createAction(ProfileFormChangeActionKeys.UPDATE);
export const updateUnsavedSuccess = createAction(
  ProfileFormChangeActionKeys.UPDATE_UNSAVEDSUCCESS,
  props<{ unsavedProfile: boolean }>(),
);

export const clear = createAction(ProfileFormChangeActionKeys.CLEAR);
export const clearSuccess = createAction(ProfileFormChangeActionKeys.CLEAR_UNSAVEDSUCCESS);
export const clearFailure = createAction(
  ProfileFormChangeActionKeys.CLEAR_UNSAVEDFAILURE,
  props<{ error: string }>(),
);


