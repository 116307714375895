import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TRANSLOCO_TRANSPILER,
  DefaultTranspiler,
  DefaultMissingHandler,
  TRANSLOCO_MISSING_HANDLER,
  TRANSLOCO_INTERCEPTOR,
  DefaultInterceptor,
  TRANSLOCO_FALLBACK_STRATEGY,
  DefaultFallbackStrategy,
} from '@jsverse/transloco';
import { Inject, inject, Injectable, NgModule } from '@angular/core';
import { APP_CONFIG } from './config/config';
import avaiLang from '../assets/availableLanguages.json';

export const avaiLangList = avaiLang.availableLanguages;

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`assets/i18n/${lang}.json`);
  }
}

@NgModule({
  imports: [TranslocoModule],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: () => {
        const config = inject(APP_CONFIG);
        return translocoConfig({
          availableLangs: avaiLangList,
          defaultLang: 'enu',
          fallbackLang: 'enu',
          reRenderOnLangChange: true,
          prodMode: config.production,
        });
      },
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_TRANSPILER, useClass: DefaultTranspiler },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: DefaultMissingHandler },
    { provide: TRANSLOCO_INTERCEPTOR, useClass: DefaultInterceptor },
    { provide: TRANSLOCO_FALLBACK_STRATEGY, useClass: DefaultFallbackStrategy },
  ],
})
export class TranslocoRootModule {}
