import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { AppState, ProfileChangeActions } from '@src/app/core/store'
import { UnsavedGuardService } from '@src/app/guards/has-unsaved/unsaved.service'

@Component({
  selector: 'app-has-unsaved',
  templateUrl: './unsavedPopup.component.html',
  styleUrls: ['./unsavedPopup.component.scss'],
})
export class UnsavedPopupComponent {
 
  constructor(private unsavedguardservice: UnsavedGuardService, public dialogRef: MatDialogRef<UnsavedPopupComponent>,
     protected store: Store<AppState>){}

  closeUnsavedModal(isNavigate:boolean){
    this.unsavedguardservice.changedUrl(isNavigate)
    this.dialogRef.close()
  }

  confirm() {
    this.store.dispatch(ProfileChangeActions.clear());
    this.dialogRef.close(false);
    
  }

  cancel() {
      this.dialogRef.close(true);
  }

}
