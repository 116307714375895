<topaz-toaster></topaz-toaster>
<div mouseDir>
  <div class="topaz-wrapper" *transloco="let t" [attr.dir]="textDirectionality">
    <lib-header
      *ngIf="showHeader"
      [auth]="fetchAuthStatus"
      [headerConfig]="fetchHeaderConfig"
      [userDetails]="fetchUserDetails"
      [headerRoles]="fetchHeaderRoles"
    >
      <div body>
        <div class="topaz-content-wrapper">
          <router-outlet></router-outlet>
        </div>
        <lib-footer></lib-footer>
      </div>
    </lib-header>
    <timeout></timeout>
  </div>
</div>
