import { createSelector } from '@ngrx/store';

import { AppState } from '../core.state';
import { ProfileState } from './profile.state';

export const selectProfileState = (state: AppState) => state.profile;

export const selectProfileLoading = createSelector(
  selectProfileState,
  (state: ProfileState) => state.loading,
);
export const selectProfileError = createSelector(
  selectProfileState,
  (state: ProfileState) => state.error,
);


export const selectPreferedLanguage = createSelector(
  selectProfileState,
  (state: ProfileState) => {
    return {
      preferredLanguageId: state.profile?.preferredLanguageId || 5,
      preferredLanguageCode: state.profile?.preferredLanguageCode || 'ENU',
    };
  },
);

export const selectRoles = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile?.roles || [],
);

export const selectUserDetails = createSelector(
  selectProfileState,
  (state: ProfileState) => {
    return {
      firstName: state.profile?.firstName || '',
      lastName: state.profile?.lastName || '',
      lastNameFirst: state.profile?.displayLastNameFirst || false,
    };
  },
);

export const selectProfileBasicInfo = createSelector(
  selectProfileState,
  (state: ProfileState) => {
    return {
      firstName: state.profile?.firstName,
      middleName: state.profile?.middleName,
      lastName: state.profile?.lastName,
      alternateName: state.profile?.alternateName,
      isNameMatchedWithGovId: state.profile?.isNameMatchedWithGovId,
      displayLastNameFirst: state.profile?.displayLastNameFirst,
      birthDate: state.profile?.birthDate,
      preferredLanguageId: state.profile?.preferredLanguageId,
      studentId: state.profile?.studentId,
      country: state.profile?.country,
      email: state.profile?.email,
      alternateEmail: state.profile?.alternateEmail      
    };
  },
);

export const selectProfileLoginContactInfo = createSelector(
  selectProfileState,
  (state: ProfileState) => {

    return {
      email: state.profile?.email,
      alternateEmail: state.profile?.alternateEmail,
      userName: state.profile?.userName,
      isMigrated: state.profile?.isMigrated
    };
  },
);

export const selectIsJapan = createSelector(
  selectProfileState,
  (state: ProfileState) => {
    return state.profile?.country.countryId == 109
  })

export const selectProfileMailingAddress = createSelector(
  selectProfileState,
  (state: ProfileState) => {
    return {
      userAddressId: state.profile?.userAddressId,
      addressLine1: state.profile?.addressLine1,
      addressLine2: state.profile?.addressLine2,
      city: state.profile?.city,
      state: state.profile?.state,
      zipCode: state.profile?.zipCode,
      country: state.profile?.country,
    };
  },
);

export const selectProfileAccommodation = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile?.accommodations || [],
);

export const selectLoginCount = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile?.loginCount,
);

export const selectProfileUserId = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile?.userId || '',
);
