import { Actions, createEffect, ofType } from "@ngrx/effects";

import { Inject, Injectable } from "@angular/core";
import { catchError, map, mergeMap, of, switchMap, timer } from "rxjs";
import { preLogout, resetTimer, refreshToken, refreshTokenSuccess, refreshTokenFailure } from "./application.action";
import { AuthService } from "../../services/auth.service";
import { APP_CONFIG, Config } from "@src/app/config/config";
import { updateSuccess } from "../session/session.actions";

@Injectable()
export class ApplicationEffects{   
    
    // this effect is called only when update success happens.
    autoLogoutWarningtimeout$ = createEffect(() => this.action$.pipe( 
        ofType(updateSuccess),              
        switchMap(() => timer(this.config.warningWindowinMS)),
        map(() =>  preLogout()))
    )
    

    resetTimer$ = createEffect(() => this.action$.pipe(
        ofType(resetTimer),
        switchMap(() => timer(this.config.warningWindowinMS)),
        map(() =>  preLogout()))
    )
   
    refreshToken$ = createEffect(() => this.action$.pipe(
        ofType(refreshToken),
        mergeMap(() => this.authService.refresh().pipe(
            map(result => refreshTokenSuccess()),
            catchError(error => 
                of(refreshTokenFailure({error: error.message}))
            )
        ))
    
    ))

   
    constructor(
        private action$: Actions,
        private authService: AuthService,
        @Inject(APP_CONFIG) readonly config: Config

    ){}
 


}


