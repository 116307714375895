<div *transloco="let t" class="tab-bar-container">
  <mat-sidenav-container
    class="sidenav-container"
    [ngClass]="{ 'side-nav-container-opened': panelOpened }"
  >
    <!--  Mobile view -->
    <mat-sidenav
      #drawer
      class="sidenav"
      fixedInViewport="false"
      [position]="'end'"
      [ngClass]="{ hidden: !(isMobileView | async)!.matches }"
      [attr.role]="isMobileView ? 'dialog' : 'navigation'"
      [mode]="(isMobileView | async)!.matches ? 'over' : 'side'"
    >
      <div class="close-icon">
        <button
          mat-icon-button
          data-test="close-button"
          role="button"
          [attr.aria-label]="'Close icon button'"
          (click)="handleClose()"
        >
          <fa-icon [icon]="icons.faXmark"></fa-icon>
        </button>
      </div>
      <div class="mobile-container">
        <div class="global-bar-mobile-container">
          <div class="top">
            @if (tabsBodyContentMobile) {
            <div>
              @if (tabsBodyContentMobile) {
              <ng-container
                [ngTemplateOutlet]="tabsBodyContentMobile.templateRefMobile"
              ></ng-container>
              }
            </div>
            } @else {
            <h2 class="active-parent-item">{{ t(activeTab.label) }}</h2>
            <div class="subview-links">
              @for (view of activeTab.subViews; track view) {
              <a
                role="link"
                class="topaz-nav-link-mobile"
                tabindex="0"
                (click)="handleSubviewClick(view, drawer)"
                >{{ t(view.label) }}</a
              >
              }
            </div>
            <div class="middle"></div>
            <div class="bottom">
              @if (!tabsBodyContentMobile) {
              <div class="parent-links">
                @for (view of nonActiveTabs; track view) {
                <h2 (click)="handleParentClick(view)">
                  {{ t(view.label) }}
                </h2>
                }
              </div>
              }
            </div>
            }
          </div>
        </div>
        <br />
        <br />
        <br />
        <div>
          @if (tabsEndContentMobile) {
          <ng-container
            [ngTemplateOutlet]="tabsEndContentMobile.templateRefMobile"
          ></ng-container>
          }
        </div>
      </div>
    </mat-sidenav>

    <!--  Desktop view -->
    <mat-sidenav-content>
      @if (tabs.length && tabs.length > 1) {
      <div class="topaz-bar-header-container">
        <div class="global-tab-bar-container">
          <mat-tab-group
            #matTabGroup
            fitInkBarToContent
            [disableRipple]="true"
            [disablePagination]="false"
            (selectedTabChange)="handleTabChange($event)"
          >
            <div class="bar-tab">
              @for (tab of tabs; track tab) {
              <mat-tab
                tabindex="0"
                labelClass="topaz-nav-tab-header"
                [label]="t(tab.label)"
              >
              </mat-tab>
              }
            </div>
          </mat-tab-group>

          <div class="header-icons">
            <ng-content select=".TopazNavLinks"> </ng-content>
          </div>
        </div>
      </div>
      }
      <topaz-global-bar
        class="global-bar-position"
        [customLogoImg]="customLogoImg"
        [logoAltText]="logoAltText"
        (logoClicked)="handleLogoClick($event)"
      >
        <topaz-global-bar-start> </topaz-global-bar-start>

        <topaz-global-bar-center>
          <div class="desktop-content-container">
            @for (item of activeTab.subViews; track item) {
            <a
              role="link"
              class="topaz-nav-link"
              tabindex="0"
              [ngClass]="{ 'topaz-nav-link--active': item.active }"
              (click)="handleSubviewClick(item, null)"
            >
              {{ t(item.label) }}
            </a>
            }
          </div>
        </topaz-global-bar-center>

        <topaz-global-bar-end>
          @if (tabsEndContent) {
          <ng-container
            [ngTemplateOutlet]="tabsEndContent.templateRef"
          ></ng-container>
          }
          <div class="global-bar-custom-content-container">
            <ng-content select=".TopazNavLinksMobile"> </ng-content>
          </div>

          <button
            *ngIf="showMenu"
            mat-icon-button
            class="global-bar-drawer-trigger-button"
            type="button"
            role="button"
            (click)="toggleDrawer(drawer)"
            [attr.aria-label]="t('common.menu')"
          >
            <mat-icon aria-label="Side nav toggle icon">menu </mat-icon>
          </button>
        </topaz-global-bar-end>
      </topaz-global-bar>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
