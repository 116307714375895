import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, finalize, first, map, mergeMap, takeUntil } from 'rxjs/operators';

import * as sessionActions from './session.actions';
import { ProfileActions } from '../profile';
import { AuthService } from '../../services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';

@Injectable()
export class SessionEffects {
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionActions.clear),
      mergeMap(() => {
        // Dispatch profile clear action
        this.store.dispatch(ProfileActions.clear());

        // Wait for profile clear success or failure
        const profileClear$ = this.actions$.pipe(
          ofType(ProfileActions.clearSuccess),
          takeUntil(this.actions$.pipe(ofType(ProfileActions.clearFailure))),
          first(),
        );

        // Ensure logout completes its endpoint calls
        const logout$ = this.auth.logout().pipe(
          first(), // Ensures that the observable completes after the first value
          catchError((err) => {
            console.error('Logout failed:', err);
            return of(false); // Fallback value for logout
          }),
        );

        // Wait for both profile clearing and logout to complete
        return forkJoin([profileClear$, logout$]).pipe(
          map(() => sessionActions.clearSuccess()), // Emit session clear success action
          finalize(() => {
            this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
    private auth: AuthService,
  ) {}
}
