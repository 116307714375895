import {
  Component,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
} from '@angular/forms';
import {
  faCaretDown,
  faCheck,
  faCircle,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFlag,
  faLock,
  faX,
  faXmark,
} from '@fortawesome/pro-light-svg-icons';
import { TranslocoService } from '@jsverse/transloco';
import { distinctUntilChanged, startWith, Subject, takeUntil, tap } from 'rxjs';
import { PasswordValidators } from 'src/libs/utility/src/lib/validators/password-validators';
import { SlideInOutAnimation } from '@src/libs/utility/src/lib/animations/animations';

@Component({
  selector: 'lib-password',
  templateUrl: './password.component.html',
  styleUrl: './password.component.scss',
  animations: [SlideInOutAnimation],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
  ],
})
export class PasswordComponent implements ControlValueAccessor, OnInit {
  @Input() label = 'Password';
  @Input() passwordHint = false;
  control!: FormControl;
  controlName!: string | number | null;

  faXmark = faXmark;
  faLock = faLock;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  faX = faX;
  faFlag = faFlag;
  faEnvelope = faEnvelope;
  faCheck = faCheck;
  faCircle = faCircle;
  faCaretDown = faCaretDown;

  showPassword = false;

  showPasswordCriterion = false;
  animationState = 'out';

  isRequired = false;
  private _onTouched!: () => any;

  private _destroy$ = new Subject<void>();

  passwordErrors: string[] = PasswordValidators.PasswordErrors;

  constructor(
    @Inject(Injector) private injector: Injector,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.setFormControl();
    this.isRequired = this.control?.hasValidator(Validators.required) ?? false;
  }

  setFormControl() {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case FormControlName:
          this.controlName = formControl.name;
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;
        default:
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
          break;
      }
    } catch (err) {
      this.control = new FormControl();
    }
  }

  writeValue(value: any): void {
    if (this.control && this.control.value != value)
      this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (val: any | null) => any): void {
    this.control?.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        startWith(this.control.value),
        distinctUntilChanged(),
        tap(val => fn(val)),
      )
      .subscribe(() => this.control?.markAsUntouched());
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  onPasswordFocusInEvent($event: FocusEvent): void {
    this.animationState = 'in';
    this.showPasswordCriterion = true;
  }

  onPasswordFocusOutEvent($event: FocusEvent): void {
    this.animationState = 'out';
    setTimeout(() => {
      if (this.animationState === 'out') {
        this.showPasswordCriterion = false;
      }
    }, 100);
  }

  hasError(): boolean {
    return !!this.control?.errors && !!this.control?.touched;
  }

  getPasswordError(validator: string): boolean {
    return this.control?.hasError(validator) || false;
  }

  passwordError(): boolean {
    return (
      !!this.control?.errors &&
      !!this.control?.touched &&
      !this.showPasswordCriterion &&
      this.animationState === 'out'
    );
  }


  getValidationText(): string {
    const password = this.control;

    if(password?.hasError('matching')){
      return this.translocoService.translate(`signup.error.matching_password`) 
    }

    let errorFieldKey = '';

    switch (this.controlName) {
      case 'currentPassword':
        errorFieldKey = 'signup.error.required_current_password';
          break;
        case 'newPassword':
          errorFieldKey = 'signup.error.required_new_password';
          break;
        case 'confirmPassword':
          errorFieldKey = 'signup.error.required_confim_password';
          break;
        default:
          errorFieldKey = 'signup.error.required_password';
          break;
    }

    return password?.hasError('required')
      ? this.translocoService.translate(errorFieldKey)
      : '';
  }
}
